import { createContext, useContext, useReducer } from 'react';
import { useSlates } from './redux';
import { useWeeks } from 'app/weeks/redux';

const SlateViewContext = createContext(null);
const SlateViewDispatchContext = createContext(null);

const slateViewReducer = (state, action) => {
  if (action.type === CLEAR_SELECTED_WEEK) {
    return {};
  } else if (action.type === SELECTED_WEEK) {
    return { ...state, week: action.week };
  } else if (action.type === SELECTED_SLATE) {
    return { ...state, [action.week]: { slate: action.slate } };
  } else {
    throw Error('Unknown action: ' + JSON.stringify(action));
  }
};

export const SlateViewProvider = ({ children }) => {
  const [ slateView, dispatch ] = useReducer(slateViewReducer, {});
  return (
    <SlateViewContext.Provider value={slateView}>
      <SlateViewDispatchContext.Provider value={dispatch}>
        { children }
      </SlateViewDispatchContext.Provider>
    </SlateViewContext.Provider>
  );
};

const CLEAR_SELECTED_WEEK = 'CLEAR_SELECTED_WEEK';
export const useClearSelectedWeek = () => {
  const dispatch = useContext(SlateViewDispatchContext);
  return () => dispatch({ type: CLEAR_SELECTED_WEEK });
};

export const useWeek = () => {
  const weeks = useWeeks();
  return useContext(SlateViewContext).week || weeks?.[0];
};

const SELECTED_WEEK = 'SELECTED_WEEK';
export const useWeekDispatch = () => {
  const dispatch = useContext(SlateViewDispatchContext);
  return week => dispatch({
    type: SELECTED_WEEK,
    week
  });
};

export const useSlate = () => {
  const week = useWeek();
  const slates = useSlates(week);
  return useContext(SlateViewContext)[week]?.slate || slates?.[0];
};

const SELECTED_SLATE = 'SELECTED_SLATE';
export const useSlateDispatch = () => {
  const dispatch = useContext(SlateViewDispatchContext);
  return (week, slate) => dispatch({
    type: SELECTED_SLATE,
    week,
    slate
  });
};


