import { putCustom } from 'app/api';
import { asUsDollars, upToTwoDecimals } from 'app/util';
import { fourTimesValue } from 'app/util/calculations';
import InterestIndicator from './InterestIndicator';
import PlayerName from './PlayerName';

const fieldsDictionary = () => {
  return {
    interest: {
      name: 'interest',
      type: 'component',
      header: '',
      tdClassname: 'w-content',
      noSorting: true,
      Component: InterestIndicator
    },
    name: {
      name: 'name',
      type: 'component',
      header: 'name',
      tdClassname: 'text-start w-content',
      isStringData: true,
      Component: PlayerName
    },
    position: {
      name: 'position',
      header: 'pos',
      thClassname: 'w-content',
      tdClassname: 'w-content',
      isStringData: true
    },
    team: {
      name: 'team',
      header: 'tm',
      thClassname: 'w-content',
      tdClassname: 'w-content',
      isStringData: true
    },
    salary: {
      name: 'salary',
      type: 'formatted',
      header: 'salary',
      thClassname: 'w-content',
      tdClassname: 'w-content',
      getFormatted: player => asUsDollars(player.salary)
    },
    fourTimesValue: {
      name: 'fourTimesValue',
      type: 'derived',
      header: 'x4',
      getValue: player => fourTimesValue(player.salary)
    },
    maxFps: {
      name: 'maxFps',
      header: 'max pts'
    },
    avgFps: {
      name: 'avgFps',
      header: 'avg pts'
    },
    covFps: {
      name: 'covFps',
      header: 'cov pts'
    },
    opponent: {
      name: 'opponent',
      header: 'opp',
      isStringData: true
    },
    oppPtsAgst: {
      name: 'oppPtsAgst',
      header: 'opa'
    },
    priorOppPtsAgst: {
      name: 'priorOppPtsAgst',
      header: 'popa'
    },
    completions: {
      name: 'completions',
      header: 'cmp'
    },
    inside20Completions: {
      name: 'inside20Completions',
      header: 'in20cmp'
    },
    inside10Completions: {
      name: 'inside10Completions',
      header: 'in10cmp'
    },
    passAttempts: {
      name: 'passAttempts',
      header: 'att'
    },
    inside20PassAttempts: {
      name: 'inside20PassAttempts',
      header: 'in20att'
    },
    inside10PassAttempts: {
      name: 'inside10PassAttempts',
      header: 'in10att'
    },
    passYds: {
      name: 'passYds',
      header: 'yds'
    },
    inside20PassYds: {
      name: 'inside20PassYds',
      header: 'in20Yds'
    },
    inside10PassYds: {
      name: 'inside10PassYds',
      header: 'ins10Yds'
    },
    ydsPerPass: {
      name: 'ydsPerPass',
      header: 'avg'
    },
    passYdsPerGame: {
      name: 'passYdsPerGame',
      header: 'yds/gm'
    },
    passTds: {
      name: 'passTds',
      header: 'tds'
    },
    inside20PassTds: {
      name: 'inside20PassTds',
      header: 'in20Tds'
    },
    inside10PassTds: {
      name: 'inside10PassTds',
      header: 'ins10Tds'
    },
    interceptions: {
      name: 'interceptions',
      header: 'ints'
    },
    inside20Interceptions: {
      name: 'inside20Interceptions',
      header: 'in20Ints'
    },
    inside10Interceptions: {
      name: 'inside10Interceptions',
      header: 'in10Ints'
    },
    rushAttempts: {
      name: 'rushAttempts',
      header: 'rsh'
    },
    inside20RushAttempts: {
      name: 'inside20RushAttempts',
      header: 'in20Rsh'
    },
    inside10RushAttempts: {
      name: 'inside10RushAttempts',
      header: 'in10Rsh'
    },
    inside5RushAttempts: {
      name: 'inside5RushAttempts',
      header: 'in5Rsh'
    },
    avgRushAttempts: {
      name: 'avgRushAttempts',
      header: 'rsh/gm'
    },
    rushYds: {
      name: 'rushYds',
      header: 'yds'
    },
    inside20RushYds: {
      name: 'inside20RushYds',
      header: 'in20Yds'
    },
    inside10RushYds: {
      name: 'inside10RushYds',
      header: 'in10Yds'
    },
    inside5RushYds: {
      name: 'inside5RushYds',
      header: 'in5Yds'
    },
    ydsPerRush: {
      name: 'ydsPerRush',
      header: 'avg'
    },
    rushYdsPerGame: {
      name: 'rushYdsPerGame',
      header: 'yds/gm'
    },
    rushTds: {
      name: 'rushTds',
      header: 'rshTds'
    },
    inside20RushTds: {
      name: 'inside20RushTds',
      header: 'in20Tds'
    },
    inside10RushTds: {
      name: 'inside10RushTds',
      header: 'in10Tds'
    },
    inside5RushTds: {
      name: 'inside5RushTds',
      header: 'in5Tds'
    },
    inside20PercentOfTeamRushAttempts: {
      name: 'inside20PercentOfTeamRushAttempts',
      header: 'in20%Att'
    },
    inside10PercentOfTeamRushAttempts: {
      name: 'inside10PercentOfTeamRushAttempts',
      header: 'in10%Att'
    },
    inside5PercentOfTeamRushAttempts: {
      name: 'inside5PercentOfTeamRushAttempts',
      header: 'in5%Att'
    },
    targets: {
      name: 'targets',
      header: 'tgts'
    },
    inside20Targets: {
      name: 'inside20Targets',
      header: 'in20Tgts'
    },
    inside10Targets: {
      name: 'inside10Targets',
      header: 'in10Tgts'
    },
    avgTargets: {
      name: 'avgTargets',
      header: 'tgts/gm'
    },
    receptions: {
      name: 'receptions',
      header: 'rec'
    },
    inside20Receptions: {
      name: 'inside20Receptions',
      header: 'in20Rec'
    },
    inside10Receptions: {
      name: 'inside10Receptions',
      header: 'in10Rec'
    },
    receivingYds: {
      name: 'receivingYds',
      header: 'yds'
    },
    inside20ReceivingYds: {
      name: 'inside20ReceivingYds',
      header: 'in20Yds'
    },
    inside10ReceivingYds: {
      name: 'inside10ReceivingYds',
      header: 'in10Yds'
    },
    ydsPerReception: {
      name: 'ydsPerReception',
      header: 'yds/rec'
    },
    recYdsPerGame: {
      name: 'recYdsPerGame',
      header: 'recYds/gm'
    },
    receivingTds: {
      name: 'receivingTds',
      header: 'recTds'
    },
    inside20ReceivingTds: {
      name: 'inside20ReceivingTds',
      header: 'in20Tds'
    },
    inside10ReceivingTds: {
      name: 'inside10ReceivingTds',
      header: 'in10Tds'
    },
    inside20PercentOfTeamTargets: {
      name: 'inside20PercentOfTeamTargets',
      header: 'in20%Tgts'
    },
    inside10PercentOfTeamTargets: {
      name: 'inside10PercentOfTeamTargets',
      header: 'in10%Tgts'
    },
    offensiveTds: {
      name: 'offensiveTds',
      header: 'offTds'
    },
    fumbles: {
      name: 'fumbles',
      header: 'fbls'
    },
    avgPercentSnaps: {
      name: 'avgPercentSnaps',
      header: 'snap %'
    },
    weightedOpportunities: {
      name: 'weightedOpportunities',
      header: 'wop'
    },
    depth: {
      name: 'depth',
      header: 'dpth'
    },
    grade: {
      name: 'grade',
      header: 'grade'
    },
    projection: {
      name: 'projection',
      header: 'prjctn'
    },
    points: {
      name: 'points',
      type: 'custom',
      defaultProperty: 'projection',
      header: 'points',
      blurAction: (slate, player, value) => putCustom(slate, player, 'points', value)
    },
    projectedValue: {
      name: 'projectedValue',
      type: 'derived',
      header: 'pVal',
      getValue: player => upToTwoDecimals(player.points / (player.salary / 1000))
    },
    target: {
      name: 'target',
      type: 'custom',
      header: 'target',
      blurAction: (slate, player, value) => putCustom(slate, player, 'target', value)
    }
  };
};

export const getFields = fieldIds => {
  const dict = fieldsDictionary();
  return fieldIds.map(id => dict[id]);
};
