import { useSlatePlayers, useSlatePlayersFilters } from './redux';
import { useFieldGroupContext } from './SlatePlayersFieldGroupContext';
import { DataTable } from 'app/components';
import { useSlate } from 'app/slates';
import SlatePlayersFilters from './SlatePlayersFilters';

const SlatePlayersDataTable = ({ additionalFilters, fieldsProvider }) => {

  const slate = useSlate();
  const filters = [ ...useSlatePlayersFilters(slate), ...additionalFilters ];
  const data = useSlatePlayers(slate).filter(player => filters.every(filter => filter.evaluate(player)));
  const { fieldGroup } = useFieldGroupContext();
  const fields = fieldsProvider(fieldGroup)

  const dataset = { id: `${slate.id}-players`, data, fields };

  const tableConfig = { recordKey: 'vendorId' };

  return (
    <>
      <SlatePlayersFilters />
      <DataTable config={tableConfig} dataset={dataset} />
    </>
  );
};

export default SlatePlayersDataTable;
