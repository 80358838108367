import { DST, QB, RB, TE, WR } from 'app/constants';

const fieldDictionary = () => {
  return {
    week: {
      name: 'week',
      header: 'wk'
    },
    versus: {
      name: 'versus',
      header: 'vs.'
    },
    plainName: {
      name: 'name',
      header: 'name',
      tdClassname: 'text-start'
    },
    score: {
      name: 'score',
      header: 'score'
    },
    offensiveSnapPercent: {
      name: 'offensiveSnapPercent',
      header: 'snap%'
    },
    completions: {
      name: 'completions',
      header: 'cmp'
    },
    passAttempts: {
      name: 'passAttempts',
      header: 'att'
    },
    passYds: {
      name: 'passYds',
      header: 'yds'
    },
    passTds: {
      name: 'passTds',
      header: 'tds'
    },
    interceptions: {
      name: 'interceptions',
      header: 'ints'
    },
    rushAttempts: {
      name: 'rushAttempts',
      header: 'rsh'
    },
    rushYds: {
      name: 'rushYds',
      header: 'yds'
    },
    ydsPerRush: {
      name: 'ydsPerRush',
      header: 'avg'
    },
    rushTds: {
      name: 'rushTds',
      header: 'tds'
    },
    targets: {
      name: 'targets',
      header: 'tgts'
    },
    receptions: {
      name: 'receptions',
      header: 'rec'
    },
    receivingYds: {
      name: 'receivingYds',
      header: 'yds'
    },
    ydsPerReception: {
      name: 'ydsPerReception',
      header: 'yds/rec'
    },
    receivingTds: {
      name: 'receivingTds',
      header: 'tds'
    },
    tmPassYds: {
      name: 'passYds',
      header: 'passYds'
    },
    tmPassTds: {
      name: 'passTds',
      header: 'passTds'
    },
    tmRushYds: {
      name: 'rushYds',
      header: 'rshYds'
    },
    tmRushTds: {
      name: 'rushTds',
      header: 'rshTds'
    },
    fumblesRecovered: {
      name: 'fumblesRecovered',
      header: 'fbl'
    },
    fantasyPoints: {
      name: 'fantasyPoints',
      header: 'fps'
    },
    snapsOffensivePercent: {
      name: 'offensiveSnapPercent',
      header: 'off %'
    },
    snapsDefensivePercent: {
      name: 'defensiveSnapPercent',
      header: 'def %'
    },
    snapsSpecialTeamsPercent: {
      name: 'specialTeamsSnapPercent',
      header: 'spt %'
    }
  };
};

const getFields = fieldIds => {
  const dict = fieldDictionary();
  return fieldIds.map(id => dict[id]);
};

const playerGamesFieldIdsFor = position => {
  switch (position) {
    case QB:
      return [
        'week',
        'versus',
        'score',
        'offensiveSnapPercent',
        'completions',
        'passAttempts',
        'passYds',
        'passTds',
        'interceptions',
        'rushAttempts',
        'rushYds',
        'rushTds',
        'fantasyPoints'
      ];
    case RB:
    case WR:
    case TE:
      return [
        'week',
        'versus',
        'score',
        'offensiveSnapPercent',
        'rushAttempts',
        'rushYds',
        'ydsPerRush',
        'rushTds',
        'targets',
        'receptions',
        'receivingYds',
        'ydsPerReception',
        'receivingTds',
        'fantasyPoints'
      ];
    case DST:
      return [
        'week',
        'versus',
        'score',
        'tmRushYds',
        'tmRushTds',
        'tmPassYds',
        'tmPassTds',
        'interceptions',
        'fumblesRecovered',
        'fantasyPoints'
      ];
    default:
      return [];
  }
};

export const playerGamesFields = position => getFields(playerGamesFieldIdsFor(position));

const pvtFieldIdsFor = position => {
  switch (position) {
    case QB:
      return [
        'week',
        'versus',
        'plainName',
        'score',
        'offensiveSnapPercent',
        'completions',
        'passAttempts',
        'passYds',
        'passTds',
        'interceptions',
        'rushAttempts',
        'rushYds',
        'rushTds',
        'fantasyPoints'
      ];
    case RB:
    case WR:
    case TE:
      return [
        'week',
        'versus',
        'plainName',
        'score',
        'offensiveSnapPercent',
        'rushAttempts',
        'rushYds',
        'rushTds',
        'receptions',
        'receivingYds',
        'receivingTds',
        'fantasyPoints'
      ];
    case DST:
      return [
        'week',
        'versus',
        'score',
        'tmRushYds',
        'tmRushTds',
        'tmPassYds',
        'tmPassTds',
        'interceptions',
        'fumblesRecovered',
        'fantasyPoints'
      ];
    default:
      return [];
  }
};

export const positionVsTeamFields = position => getFields(pvtFieldIdsFor(position));

export const playerSnapsFields = () => getFields([
  'week',
  'versus',
  'score',
  'snapsOffensivePercent',
  'snapsDefensivePercent',
  'snapsSpecialTeamsPercent'
]);
