import { useMemo } from 'react';
import { editorFields } from './fields';
import { useLineupEditorContext } from './LineupEditorContext';
import { FLEX1, FLEX2, FLEX3, FLEX4, FLEX5, RB1, RB2, WR1, WR2, WR3 } from '../constants';
import { DataTable } from 'app/components';
import { CLASSIC, CPT, DST, FLEX, QB, TE } from 'app/constants';
import { useSlate } from 'app/slates';

const LineupColumn = ({ players }) => {

  const dataset = { data: players, fields: editorFields() };
  const tableConfig = { header: false, sorting: false, classNames: 'table-borderless' };

  return (
    <div className="col-4 lineup-column">
      <DataTable config={tableConfig} dataset={dataset} />
    </div>
  );
};

const LineupPlayers = () => {

  const slate = useSlate();
  const { lineup } = useLineupEditorContext();

  const positions = useMemo(() => {
    return slate.contestType === CLASSIC
      ? [ [ QB, RB1, RB2 ], [ WR1, WR2, WR3 ], [ TE, FLEX, DST ] ]
      : [ [ CPT, FLEX1 ], [ FLEX2, FLEX3 ], [ FLEX4, FLEX5 ] ];
  }, [ slate ]);

  return (
    <>
      { positions.map(group => <LineupColumn key={group[0]} players={lineup.get(group)} />) }
    </>
  );
};

export default LineupPlayers;
