import { useSelector } from 'react-redux';

const SORT_DATASET = 'SORT_DATASET';
export const sortField = (datasetId, field) => ({
  type: SORT_DATASET,
  datasetId,
  field
});

export const useSortField = datasetId => {
  return useSelector(({ sorting }) => sorting[datasetId]);
};

const sorting = (state = {}, action) => {
  if (action.type === SORT_DATASET) {
    return { ...state, [action.datasetId]: action.field };
  } else {
    return state;
  }
};

export default sorting;
