import HomeAwayFilter, { useHomeAwayFilter } from './HomeAwayFilter';
import { InjuryReport } from 'app/injuries';
import { useSlate } from 'app/slates';

const InjuryReportTab = () => {

  const slate = useSlate();
  const additionalFilters = [ useHomeAwayFilter(slate) ];

  return (
    <div className="my-4">
      <div className="ms-2 mb-3">
        <HomeAwayFilter slate={slate} tab="injury-report"/>
      </div>
      <div>
        <InjuryReport additionalFilters={additionalFilters}/>
      </div>
    </div>
  );
};

export default InjuryReportTab;
