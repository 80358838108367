import { combineReducers } from 'redux';
import { useSelector } from 'react-redux';
import { get } from 'app/util/rest-client';


/**
 * api
 */
const FETCHING = 'FETCHING';
const _fetching = request => ({
  type: FETCHING,
  request
});

export const FETCH_FAILED = 'FETCH_FAILED';
const _fetchFailed = (request, status) => ({
  type: FETCH_FAILED,
  request,
  status
});

const API_RESPONSE = 'API_RESPONSE';
const _response = (request, response) => ({
  type: API_RESPONSE,
  request,
  response
});

const CLEAR_DATA = 'CLEAR_DATA';
const _clearData = () => ({
  type: CLEAR_DATA
});


const fetching = (state = {}, action) => {
  if (action.type === FETCHING) {
    return { ...state, [action.request.path]: true };
  } else if ([ API_RESPONSE, FETCH_FAILED ].includes(action.type)) {
    return { ...state, [action.request.path]: false };
  } else {
    return state;
  }
};

const received = (state = {}, action) => {
  if (action.type === FETCHING) {
    return { ...state, [action.request.path]: false };
  } else if (action.type === API_RESPONSE) {
    return { ...state, [action.request.path]: true };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

export const api = combineReducers({ fetching, received });


const _get = request => {
  return (dispatch, getState) => {
    if (!request.path) {
      return;
    }
    const api = getState().api;
    if (api.fetching[request.path] || api.received[request.path]) {
      return;
    }
    dispatch(_fetching(request));
    get(request.path)
      .then(response => response.json())
      .then(json => dispatch(_response(request, json)))
      .catch(e => dispatch(_fetchFailed(request, e.status)));
  };
};

export const getWeeks = () => (() => _get({
  type: 'weeks',
  path: '/api/weeks'
}))();

export const getData = week => (() => _get({
  type: 'data',
  path: week && `/api/weeks/${week}`
}))();

export const getWeather = week => (() => _get({
  type: 'weather',
  week,
  path: week && `/api/weather/${week}`
}))();

export const resetApiData = () => {
  return dispatch => {
    dispatch(_clearData());
    dispatch(getWeeks());
  };
};

const isDataRequestResponse = action => action.type === API_RESPONSE && action.request.type === 'data';

/**
 * games
 */
export const games = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: action.response.games };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

export const useGames = slate => {
  return useSelector(({ games }) => games[slate.week])?.filter(game => slate.gameIds.includes(game.id));
};

export const useGame = (team, week) => {
  return useSelector(({ games }) => games[week]).find(game => [ game.home, game.away ].includes(team));
};


/**
 * injuries
 */
export const useInjuries = week => Object
  .values(usePlayers(week))
  .filter(player => player.hasStatusData);


/**
 * matchups
 */
export const matchups = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: _receivedMatchups(state, action) };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const _receivedMatchups = (state, action) => {
  const teams = action.response.teams[action.response.week];
  return action.response.games.flatMap(game => {
    return [
      {
        id: `${game.home}_${game.away}`,
        total: game.total,
        offense: {
          name: game.home,
          implied: game.homeImplied,
          injuriesSummary: teams[game.home].injuriesSummary,
          lastGame: teams[game.home].daysSinceLastGame,
          ...teams[game.home].offense
        },
        defense: {
          name: game.away,
          injuriesSummary: teams[game.away].injuriesSummary,
          lastGame: teams[game.away].daysSinceLastGame,
          ...teams[game.away].defense
        }
      },
      {
        id: `${game.away}_${game.home}`,
        total: game.total,
        offense: {
          name: game.away,
          implied: game.awayImplied,
          injuriesSummary: teams[game.away].injuriesSummary,
          lastGame: teams[game.away].daysSinceLastGame,
          ...teams[game.away].offense
        },
        defense: {
          name: game.home,
          injuriesSummary: teams[game.home].injuriesSummary,
          lastGame: teams[game.home].daysSinceLastGame,
          ...teams[game.home].defense
        }
      }
    ];
  });
};

export const useMatchups = week => useSelector(({ matchups }) => matchups[week]);


/**
 * players
 */
const PUT_CUSTOM = 'PUT_CUSTOM';
export const putCustom = (slate, player, key, value) => ({
  type: PUT_CUSTOM,
  slate,
  player,
  key,
  value
});

const SET_INTEREST = 'SET_INTEREST';
export const setInterest = (slateId, player, interest) => ({
  type: SET_INTEREST,
  slateId,
  player,
  interest
});


const playersByWeek = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: action.response.players[action.response.week] };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const playerGames = (state = {}, action) => {
  if (isDataRequestResponse(action) && (!state.week || state.week < action.response.week)) {
    return { ...action.response.players.games, week: action.response.week };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const playerSlates = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, ...action.response.players.slates };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const custom = (state = {}, action) => {
  if (action.type === PUT_CUSTOM) {
    return { ...state, [action.slate.id]: _newCustom(state, action) };
  } else {
    return state;
  }
};

const _newCustom = (state, action) => {
  const slate = { ...state[action.slate.id] };
  const playerId = action.player.vendorId;
  const value = Number(action.value);
  if (value) {
    slate[playerId] = { ...slate[playerId], [action.key]: value };
  } else {
    delete slate[playerId]?.[action.key];
  }
  return slate;
};

const interests = (state = {}, action) => {
  if (action.type === SET_INTEREST) {
    return { ...state, [action.slateId]: { ...state[action.slateId], [action.player.vendorId]: action.interest } };
  } else {
    return state;
  }
};

export const players = combineReducers({
  byWeek: playersByWeek,
  slates: playerSlates,
  games: playerGames,
  interests,
  custom
});

export const usePlayers = week => useSelector(({ players }) => players.byWeek[week]);

export const usePlayer = (week, playerId) => usePlayers(week)[playerId];

export const usePlayerStats = week => Object
  .values(usePlayers(week))
  .filter(player => player.hasSeasonStats);


/**
 * pvt
 */
export const pvt = (state = {}, action) => {
  if (isDataRequestResponse(action) && (!state.week || state.week < action.response.week)) {
    return { ...action.response.pvt, week: action.response.week };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

export const usePositionVsTeamData = (position, team, week) => {
  const positionVsTeam = useSelector(({ pvt }) => pvt);
  return !!position && !!team && positionVsTeam[position]?.[team].filter(datum => datum.week < week);
};


/**
 * slates
 */
export const slates = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: action.response.slates };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};


/**
 * teams
 */
const teamsByWeek = (state = {}, action) => {
  if (isDataRequestResponse(action)) {
    return { ...state, [action.response.week]: action.response.teams[action.response.week] };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

const teamGames = (state = {}, action) => {
  if (isDataRequestResponse(action) && (!state.week || state.week < action.response.week)) {
    return { ...action.response.teams.games, week: action.response.week };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};

export const teams = combineReducers({
  byWeek: teamsByWeek,
  games: teamGames
});

export const useTeams = week => {
  return useSelector(({ teams }) => teams.byWeek[week]);
};

export const useTeam = (team, week) => useTeams(week)[team];

export const useDepthChart = (team, week) => useTeams(week)[team].depthChart;

const useUnitStats = (unit, week) => {
  const teams = useTeams(week);
  return Object.values(teams).map(team => ({ ...team, ...team[unit] }));
};

export const useDefenseStats = week => useUnitStats('defense', week);

export const useOffenseStats = week => useUnitStats('offense', week);

/**
 * weather forecasts
 */
export const weather = (state = {}, action) => {
  if (action.type === API_RESPONSE && action.request.type === 'weather') {
    return { ...state, [action.request.week]: action.response };
  } else if (action.type === CLEAR_DATA) {
    return {};
  } else {
    return state;
  }
};


/**
 * weeks
 *
 * weeks.values defaults to null as compared to an empty array to clarify the distinction between
 * weeks having not been loaded from the server (i.e., null) vs. the server responding with an empty
 * array of weeks.values
 */
export const weeks = (state = null, action) => {
  if (action.type === API_RESPONSE && action.request.type === 'weeks') {
    return action.response;
  } else if (action.type === CLEAR_DATA) {
    return null;
  } else {
    return state;
  }
};
