import { createElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSlate } from 'app/slates';
import { ASC, DESC, sort, sortField, useSortField } from 'app/sorting';
import { getProperty } from 'app/util';

const Headers = ({ config, dataset }) => {
  return (
    <thead className="table-light">
      <tr>
        {
          dataset.fields.map(field => {
            return config.sorting && !field.noSorting
              ? <Header key={field.name} datasetId={dataset.id} field={field} />
              : <NoSortHeader key={field.name} field={field} />;
          })
        }
      </tr>
    </thead>
  );
};

const Header = ({ datasetId, field }) => {

  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(sortField(datasetId, field));
  }, [ datasetId, dispatch, field ]);

  const thClassname = field.thClassname ? `${field.thClassname} pointer` : 'pointer';

  return (
    <th className={thClassname} onClick={onClick}>
      <span className="me-1">{field.header}</span>
      <SortIcon field={field} />
    </th>
  );
};

const SortIcon = ({ field }) => {
  let icon = makeIcon(field);
  return <FontAwesomeIcon icon={icon.name} color={icon.color} size="sm" />;
};

const makeIcon = field => {
  switch (field.orderedBy) {
    case ASC:
      return { name: 'sort-up', color: '#555' };
    case DESC:
      return { name: 'sort-down', color: '#555' };
    default:
      return { name: 'sort', color: '#aaa' };
  }
};

const NoSortHeader = ({ field }) => {
  return (
    <th className={field.thClassname || ''}>
      <span className="me-1">{field.header}</span>
    </th>
  );
};

const Body = ({ config, data, fields }) => {
  return (
    <tbody>
      {
        data.map(record => (
          <Row
            key={record[config.recordKey]}
            config={config}
            fields={fields}
            record={record}
          />
        ))
      }
    </tbody>
  );
};

const Row = ({ config, fields, record }) => {
  return (
    <tr className={config.rowClassNameGenerator(record)}>
      {
        fields.map(field => (
          <Cell
            key={`${record[config.recordKey]}-${field.name}`}
            config={config}
            field={field}
            record={record}
          />
        ))
      }
    </tr>
  );
};

const Cell = ({ config, field, record }) => {
  return <td className={field.tdClassname || ''}>{render(record, field, config)}</td>;
};

const render = (record, field, config) => {
  switch (field.type) {
    case 'component':
      return createElement(field.Component, { record });
    case 'derived':
      return field.getValue(record);
    case 'formatted':
      return field.getFormatted(record);
    case 'custom':
      return <CustomInput config={config} field={field} record={record} />;
    default:
      return getProperty(record, field.name);
  }
};

const CustomInput = ({ config, field, record }) => {

  const slate = useSlate();
  const dispatch = useDispatch();
  const [ value, setValue ] = useState(record[field.name] || '');

  const onChange = useCallback(e => setValue(e.target.value), [ setValue ]);

  const onBlur = useCallback(e => {
    const value = e.target.value;
    dispatch(field.blurAction(slate, record, value));
    if (!value && field.defaultProperty) {
      setValue(record[field.defaultProperty]);
    }
  }, [ dispatch, field, record, slate ]);

  return (
    <input
      type="text"
      className="text-input text-center"
      id={`${field.name}-${record[config.recordKey]}`}
      onBlur={onBlur}
      onChange={onChange}
      size="5"
      value={value}
    />
  );
};

const DataTable = ({ dataset, config }) => {

  const _config = {
    header: true,
    sorting: true,
    syncQueue: true,
    recordKey: 'id',
    classNames: 'border-sides',
    prependFields: [],
    rowClassNameGenerator: () => '',
    ...config
  };

  const sorted = sort(dataset, useSortField(dataset.id));

  return (
    <table className={`table table-hover align-middle text-center ${_config.classNames}`}>
      { !!_config.header && <Headers config={_config} dataset={sorted} /> }
      <Body config={_config} data={sorted.data} fields={sorted.fields} />
    </table>
  );
};

export default DataTable;
