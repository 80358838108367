import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LineupEditorProvider, useLineupEditorContext } from './LineupEditorContext';
import { SlatePlayersDataTable, slatePlayersFields } from 'app/players';
import { useSlate, useSlateTeamsFilter } from 'app/slates';
import LineupBoard from './LineupBoard';
import useLineupFactory from '../LineupFactory';

const AddToLineup = ({ record }) => {

  const slate = useSlate();
  const lineupFactory = useLineupFactory(slate);
  const { lineup, setLineup } = useLineupEditorContext();

  const addable = player => !lineup.hasPlayer(player) && lineup.hasOpen(player.slatePosition);

  const addToLineup = player => () => {
    lineup.put(player);
    setLineup(lineupFactory.make(lineup));
  };

  return addable(record)
    ? (
      <FontAwesomeIcon
        icon="fa-solid fa-circle-plus"
        color="#28a745"
        size="xl"
        className="pointer"
        onClick={addToLineup(record)}
      />
    )
    : (
      <FontAwesomeIcon
        icon="fa-solid fa-lock"
        color="#ccc"
        size="lg"
      />
    );
};

const addToLineupField = {
  name: 'addToLineup',
  type: 'component',
  header: '',
  noSorting: true,
  Component: AddToLineup
};

const LineupsEditorView = () => {

  const slate = useSlate();
  const fieldsProvider = useCallback(fieldGroup => [ addToLineupField, ...slatePlayersFields(fieldGroup) ], []);

  return (
    <>
      <div className="my-4">
        <LineupBoard />
      </div>
      <div>
        <SlatePlayersDataTable additionalFilters={useSlateTeamsFilter(slate)} fieldsProvider={fieldsProvider} />
      </div>
    </>
  );
};

const LineupsEditorViewWrapper = () => {
  return (
    <LineupEditorProvider>
      <LineupsEditorView />
    </LineupEditorProvider>
  );
};

export default LineupsEditorViewWrapper;
