import { useCallback } from 'react';
import { useSelectedTeamDispatch } from './modal';
import { secondsToMinutes } from 'app/util/calculations';
import { getProperty } from 'app/util';

export const makeTeamModalLink = (unit, property = 'name') => Object.assign(
  ({ record }) => {
    const dispatchSelectedTeam = useSelectedTeamDispatch();
    const onClick = useCallback(() => {
      dispatchSelectedTeam({ name: getProperty(record, property), unit });
    }, [ dispatchSelectedTeam, record ]);
    return <span className="link" onClick={onClick}>{getProperty(record, property)}</span>;
  },
  { displayName: 'TeamModalLink' }
);

const fieldDictionary = () => {

  return {
    offTeam: {
      name: 'name',
      type: 'component',
      header: 'tm',
      isStringData: true,
      Component: makeTeamModalLink('Offense')
    },
    defTeam: {
      name: 'name',
      type: 'component',
      header: 'tm',
      isStringData: true,
      Component: makeTeamModalLink('Defense')
    },
    week: {
      name: 'week',
      header: 'wk'
    },
    versus: {
      name: 'versus',
      header: 'vs.'
    },
    score: {
      name: 'score',
      header: 'score'
    },
    completions: {
      name: 'completions',
      header: 'cmp'
    },
    passAttempts: {
      name: 'passAttempts',
      header: 'att'
    },
    passesPerGame: {
      name: 'passesPerGame',
      header: 'pass/gm'
    },
    completionPercentage: {
      name: 'completionPercentage',
      header: 'cmp %'
    },
    passYds: {
      name: 'passYds',
      header: 'yds'
    },
    ydsPerPass: {
      name: 'ydsPerPass',
      header: 'avg'
    },
    tmPassYdsPerGame: {
      name: 'passYdsPerGame',
      header: 'passYds/gm'
    },
    passTds: {
      name: 'passTds',
      header: 'tds'
    },
    tmPassTds: {
      name: 'passTds',
      header: 'passTds'
    },
    interceptions: {
      name: 'interceptions',
      header: 'ints'
    },
    rushAttempts: {
      name: 'rushAttempts',
      header: 'rsh'
    },
    rushesPerGame: {
      name: 'rushesPerGame',
      header: 'rsh/gm'
    },
    rushYds: {
      name: 'rushYds',
      header: 'yds'
    },
    ydsPerRush: {
      name: 'ydsPerRush',
      header: 'avg'
    },
    avgRushYds: {
      name: 'avgRushYds',
      header: 'yds/rsh'
    },
    rushYdsPerGame: {
      name: 'rushYdsPerGame',
      header: 'rshYds/gm'
    },
    rushTds: {
      name: 'rushTds',
      header: 'tds'
    },
    tmRushTds: {
      name: 'rushTds',
      header: 'rshTds'
    },
    fumblesRecovered: {
      name: 'fumblesRecovered',
      header: 'fbl'
    },
    fumblesLost: {
      name: 'fumblesLost',
      header: 'fbl'
    },
    pointsPerGame: {
      name: 'pointsPerGame',
      header: 'pts/gm'
    },
    sacks: {
      name: 'sacks',
      header: 'scks'
    },
    sacksAllowed: {
      name: 'sacksAllowed',
      header: 'scks'
    },
    turnovers: {
      name: 'turnovers',
      header: 'tos'
    },
    fourthDownAttempts: {
      name: 'fourthDownAttempts',
      header: '4DAs'
    },
    paceOfPlay: {
      name: 'paceOfPlay',
      header: 'pace'
    },
    timeOfPossession: {
      name: 'timeOfPossession',
      type: 'formatted',
      header: 'top',
      getFormatted: team => secondsToMinutes(team['timeOfPossession'])
    },
    offTop: {
      name: 'avgTimeOfPossession',
      type: 'formatted',
      header: 'avgTOP',
      getFormatted: team => secondsToMinutes(team['avgTimeOfPossession'])
    },
    defTop: {
      name: 'avgTimeOfPossession',
      type: 'formatted',
      header: 'avgOppTOP',
      getFormatted: team => secondsToMinutes(team['avgTimeOfPossession'])
    }
  };
};

const getFields = fieldIds => {
  const dict = fieldDictionary();
  return fieldIds.map(id => dict[id]);
};

export const offenseGameFields = () => getFields([
  'week',
  'versus',
  'score',
  'rushAttempts',
  'rushYds',
  'ydsPerRush',
  'rushTds',
  'completions',
  'passAttempts',
  'passYds',
  'ydsPerPass',
  'passTds',
  'interceptions',
  'fumblesLost',
  'sacksAllowed',
  'timeOfPossession'
]);

export const defenseGameFields = () => getFields([
  'week',
  'versus',
  'score',
  'rushAttempts',
  'rushYds',
  'ydsPerRush',
  'rushTds',
  'completions',
  'passAttempts',
  'passYds',
  'ydsPerPass',
  'passTds',
  'interceptions',
  'fumblesRecovered',
  'sacks',
  'timeOfPossession'
]);

export const offenseStatsFields = () => getFields([
  'offTeam',
  'pointsPerGame',
  'rushesPerGame',
  'rushYdsPerGame',
  'tmRushTds',
  'passesPerGame',
  'tmPassYdsPerGame',
  'tmPassTds',
  'sacksAllowed',
  'turnovers',
  'fourthDownAttempts',
  'paceOfPlay',
  'offTop'
]);

export const defenseStatsFields = () => getFields([
  'defTeam',
  'pointsPerGame',
  'rushYdsPerGame',
  'avgRushYds',
  'tmRushTds',
  'tmPassYdsPerGame',
  'completionPercentage',
  'tmPassTds',
  'sacks',
  'turnovers',
  'defTop'
]);
