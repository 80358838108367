import { getFields } from '../fields';

export const fieldGroups = () => [
  {
    id: 'dfs',
    text: 'DFS'
  },
  {
    id: 'passing',
    text: 'Passing'
  },
  {
    id: 'rushing',
    text: 'Rushing'
  },
  {
    id: 'receiving',
    text: 'Receiving'
  },
  {
    id: 'red_zone_passing',
    text: 'Red Zone Passing'
  },
  {
    id: 'red_zone_rushing',
    text: 'Red Zone Rushing'
  },
  {
    id: 'red_zone_receiving',
    text: 'Red Zone Receiving'
  },
  {
    id: 'scoring',
    text: 'Scoring'
  }
];

export const defaultFieldGroup = () => fieldGroups()[0];

const fieldIdsFor = fieldGroupId => {
  return {
    dfs: [
      'interest',
      'name',
      'position',
      'team',
      'salary',
      'fourTimesValue',
      'maxFps',
      'avgFps',
      'opponent',
      'oppPtsAgst',
      'priorOppPtsAgst',
      'weightedOpportunities',
      'avgPercentSnaps',
      'depth',
      'grade',
      'points',
      'projectedValue',
      'target'
    ],
    passing: [
      'interest',
      'name',
      'position',
      'team',
      'salary',
      'completions',
      'passAttempts',
      'passYds',
      'ydsPerPass',
      'passTds',
      'interceptions',
      'fumbles',
      'passYdsPerGame'
    ],
    red_zone_passing: [
      'interest',
      'name',
      'position',
      'team',
      'salary',
      'inside20Completions',
      'inside20PassAttempts',
      'inside20PassYds',
      'inside20PassTds',
      'inside20Interceptions',
      'inside10Completions',
      'inside10PassAttempts',
      'inside10PassYds',
      'inside10PassTds',
      'inside10Interceptions'
    ],
    rushing: [
      'interest',
      'name',
      'position',
      'team',
      'salary',
      'rushAttempts',
      'rushYds',
      'ydsPerRush',
      'rushTds',
      'fumbles',
      'avgRushAttempts',
      'rushYdsPerGame'
    ],
    red_zone_rushing: [
      'interest',
      'name',
      'position',
      'team',
      'salary',
      'inside20RushAttempts',
      'inside20RushYds',
      'inside20RushTds',
      'inside20PercentOfTeamRushAttempts',
      'inside10RushAttempts',
      'inside10RushYds',
      'inside10RushTds',
      'inside10PercentOfTeamRushAttempts',
      'inside5RushAttempts',
      'inside5RushYds',
      'inside5RushTds',
      'inside5PercentOfTeamRushAttempts'
    ],
    receiving: [
      'interest',
      'name',
      'position',
      'team',
      'salary',
      'targets',
      'avgTargets',
      'receptions',
      'receivingYds',
      'ydsPerReception',
      'recYdsPerGame',
      'receivingTds',
      'fumbles'
    ],
    red_zone_receiving: [
      'interest',
      'name',
      'position',
      'team',
      'salary',
      'inside20Targets',
      'inside20Receptions',
      'inside20ReceivingYds',
      'inside20ReceivingTds',
      'inside20PercentOfTeamTargets',
      'inside10Targets',
      'inside10Receptions',
      'inside10ReceivingYds',
      'inside10ReceivingTds',
      'inside10PercentOfTeamTargets'
    ],
    scoring: [
      'interest',
      'name',
      'position',
      'team',
      'salary',
      'rushTds',
      'receivingTds',
      'offensiveTds'
    ]
  }[fieldGroupId];
};

const fieldsFor = fieldGroupId => getFields(fieldIdsFor(fieldGroupId));

export const slatePlayersFields = fieldGroup => fieldsFor(fieldGroup.id);
