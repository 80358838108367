import { createContext, useContext, useReducer } from 'react';
import TeamModal from './TeamModal';

const TeamModalContext = createContext(null);
const TeamModalDispatchContext = createContext(null);

/*
 * actions
 */
const SELECTED_TEAM = 'SELECTED_TEAM';
export const useSelectedTeamDispatch = () => {
  const dispatch = useContext(TeamModalDispatchContext);
  return team => dispatch({
    type: SELECTED_TEAM,
    team
  });
};

/*
 * reducer
 */
const teamModalReducer = (state, action) => {
  if (action.type === SELECTED_TEAM) {
    return { ...state, team: action.team };
  } else {
    throw Error('Unknown action: ' + JSON.stringify(action));
  }
};

/*
 * hooks
 */
export const useSelectedTeam = () => {
  return useContext(TeamModalContext).team;
};

/*
 * component
 */
export const TeamModalProvider = ({ children }) => {
  const [ teamModal, dispatch ] = useReducer(teamModalReducer, {});
  return (
    <TeamModalContext.Provider value={teamModal}>
      <TeamModalDispatchContext.Provider value={dispatch}>
        { children }
        <TeamModal />
      </TeamModalDispatchContext.Provider>
    </TeamModalContext.Provider>
  );
};
