import { playerStatsFields } from './fields';
import { useFieldGroupContext } from './PlayerStatsFieldGroupContext';
import { usePlayerStats } from 'app/api';
import { DataTable } from 'app/components';
import { useSlate } from 'app/slates';
import MultiPositionFilter, { useMultiPositionFilterData } from './MultiPositonFilter';
import PlayerStatsFieldGroupSelector from './PlayerStatsFieldGroupSelector';

const usePositionFilter = slate => {
  const positions = Object.entries(useMultiPositionFilterData(slate))
    .filter(([ _, v ]) => v)
    .map(([ k ]) => k);
  return { evaluate: player => positions.length === 0 || positions.includes(player.position) };
};

const PlayerStats = ({ additionalFilters }) => {

  const slate = useSlate();
  const filters = [ usePositionFilter(slate), ...additionalFilters ];
  const data = usePlayerStats(slate.week).filter(player => filters.every(filter => filter.evaluate(player)));
  const { fieldGroup } = useFieldGroupContext();
  const fields = playerStatsFields(fieldGroup);

  const dataset = { id: `${slate.id}-player-stats`, data, fields };

  return (
    <div className="my-4">
      <div className="mb-3">
        <div className="ms-2 mb-3">
          <MultiPositionFilter />
        </div>
      </div>
      <div>
        <PlayerStatsFieldGroupSelector />
      </div>
      <div>
        <DataTable dataset={dataset} />
      </div>
    </div>
  );
};

export default PlayerStats;
