import { lineupFields } from '../fields';
import RemovePlayer from './RemovePlayer';

export const editorFields = () => [
  ...lineupFields(),
  {
    name: 'remove',
    type: 'component',
    header: '',
    Component: RemovePlayer
  }
];
