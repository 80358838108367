import { useCallback } from 'react';
import { useSelectedPlayerIdDispatch, useShowPlayerModalDispatch } from './modal';
import { indication } from 'app/injuries';

const PlayerName = ({ record }) => {

  const select = useSelectedPlayerIdDispatch();
  const showModal = useShowPlayerModalDispatch();
  const statusIndication = indication(record.status);

  const onClick = useCallback(() => {
    select(record.id);
    showModal(true);
  }, [ record, select, showModal ]);

  return (
    <>
      <span className="link" onClick={onClick}>{record.name}</span>
      <span className="status-indication">{statusIndication}</span>
    </>
  );
};

export default PlayerName;
