import { createContext, useContext, useMemo, useState } from 'react';
import { defaultFieldGroup } from './fields';

const PlayerStatsFieldGroupContext = createContext(null);

export const useFieldGroupContext = () => useContext(PlayerStatsFieldGroupContext);

export const PlayerStatsFieldGroupProvider = ({ children }) => {
  const [ fieldGroup, setFieldGroup ] = useState(defaultFieldGroup().id);
  const initial = useMemo(() => ({
    fieldGroup,
    setFieldGroup
  }), [ fieldGroup, setFieldGroup ]);
  return (
    <PlayerStatsFieldGroupContext.Provider value={initial}>
      { children }
    </PlayerStatsFieldGroupContext.Provider>
  );
};
