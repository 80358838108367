import { useSelectedGame, useSelectedGameDispatch } from './GamesViewContext';
import { useGames } from 'app/api';
import { GameBox } from 'app/components';
import { useSlate } from 'app/slates';

const GameSelector = () => {

  const slate = useSlate();
  const games = useGames(slate);
  const selected = useSelectedGame(slate);
  const select = useSelectedGameDispatch();

  const handlers = {
    game: game => select(slate, game),
    team: team => select(slate, games.find(game => game.home === team || game.away === team))
  };

  return (
    <div className="border-start">
      { games.map(game => (
        <GameBox
          key={game.home}
          game={game}
          activeTeams={[ selected.home, selected.away ]}
          clickHandlers={handlers}
        />
      ))}
    </div>
  );
};

export default GameSelector;
