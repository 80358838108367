import { useCallback } from 'react';
import { useLineupEditorContext } from './LineupEditorContext';
import { useLikedPlayers, useLockedPlayers, useSlatePlayers } from 'app/players';
import { useSlate } from 'app/slates';
import { useShowOptimizerMessageDispatch } from 'app/view';
import useLineupFactory from '../LineupFactory';
import optimizer from '../optimizer';

const OptimizerButton = () => {

  const slate = useSlate();
  const likes = useLikedPlayers(slate);
  const locks = useLockedPlayers(slate);
  const slatePlayers = useSlatePlayers(slate).filter(sp => !sp.status || sp.status.toLowerCase() === 'questionable');
  const lineupFactory = useLineupFactory(slate);
  const { lineup, setLineup } = useLineupEditorContext();
  const dispatchShowOptimizerMessage = useShowOptimizerMessageDispatch();

  const clickHandler = useCallback(() => {

    const convertToLineup = optimal => {
      return optimal.players()
        .reduce((editable, player) => {
          editable.put(player);
          return editable;
        }, lineupFactory.make({ _id: lineup._id }));
    };

    const validate = converted => {
      if (!converted.isValid()) {
        dispatchShowOptimizerMessage();
      }
      return converted;
    };

    const options = likes.length ? likes : slatePlayers;

    optimizer
      .for(slate.contestType)
      .generate(options, locks)
      .then(convertToLineup)
      .then(validate)
      .then(setLineup);

  }, [ dispatchShowOptimizerMessage, likes, lineup, lineupFactory, locks, setLineup, slate, slatePlayers ]);

  return (
    <span className="me-3">
      <button type="button" className="btn btn-outline-dark d-inline-block" onClick={clickHandler}>Optimal</button>
    </span>
  );
};

export default OptimizerButton;
