import { analysisFields } from './fields';
import { useAnalysis } from './redux';
import { DataTable } from 'app/components';
import { SlatePlayersPositionFilter, usePositionFilter } from 'app/players';

const Analysis = ({ slate, lineups }) => {

  const filters = usePositionFilter(slate);
  const data = useAnalysis(slate).filter(player => filters.every(filter => filter.evaluate(player)));
  const fields = analysisFields();

  const dataset = { id: 'analysis', data, fields };

  const tableConfig = { recordKey: 'vendorId' };

  return (
    <>
      <div className="row px-1 mb-2">
        <h4>Analysis</h4>
      </div>
      <div className="border py-2 px-3">
        <div className="mb-4">
          <span className="fw-bold">Lineups Count: </span>
          { lineups.length }
        </div>
        <div>
          <div>
            <div className="row">
              <div className="col">
                <SlatePlayersPositionFilter />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <DataTable config={tableConfig} dataset={dataset} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analysis;
