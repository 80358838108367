import { clearFilter, putFilter, useFilterData, useRegisteredFilters } from 'app/filters';

/*
 * actions
 */
const MIN_GAMES_PLAYED = 'minGamesPlayed';
export const putMinGamesPlayedFilter = (slateId, gamesPlayed) => putFilter({
  slateId,
  filter: {
    [MIN_GAMES_PLAYED]: {
      data: gamesPlayed
    }
  }
});

const MIN_SNAP_PERCENTAGE = 'minSnapPercentage';
export const putMinSnapPercentageFilter = (slateId, percentage) => putFilter({
  slateId,
  filter: {
    [MIN_SNAP_PERCENTAGE]: {
      data: percentage
    }
  }
});

const STATUS = 'status';
export const putStatusFilter = slateId => putFilter({
  slateId,
  filter: {
    [STATUS]: {}
  }
});

export const clearStatusFilter = slateId => clearFilter(slateId, STATUS);

/*
 * hooks
 */
export const useInjuryReportFilters = slate => {
  return useRegisteredFilters(slate, [ MIN_GAMES_PLAYED, MIN_SNAP_PERCENTAGE, STATUS ])
    .map(([ type, filter ]) => ({ type, data: filter.data }))
    .map(makeFilter);
};

export const useMinGamesPlayedFilterData = slate => {
  return useFilterData(slate, MIN_GAMES_PLAYED)?.data || '';
};

export const useMinSnapPercentageFilterData = slate => {
  return useFilterData(slate, MIN_SNAP_PERCENTAGE)?.data || '';
};

export const useStatusFilterIsEnabled = slate => {
  return !!useFilterData(slate, STATUS);
};

/*
 * utils
 */
const methods = {
  [MIN_GAMES_PLAYED]: fd => {
    return {
      evaluate: player => fd.data === '' || (player.gamesPlayed && fd.data <= player.gamesPlayed)
    };
  },
  [MIN_SNAP_PERCENTAGE]: fd => {
    return {
      evaluate: player => fd.data === '' || (player.avgPercentSnaps && fd.data <= player.avgPercentSnaps)
    };
  },
  [STATUS]: () => {
    return {
      evaluate: player => !!player.status
    };
  }
};

export const makeFilter = fd => methods[fd.type](fd);
