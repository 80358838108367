import { getProperty, hasProperty } from 'app/util';

export { sortField, useSortField } from './redux';

export const ASC = 1;
export const DESC = -1;
export const NONE = 0;

export const sort = (dataset, sortField) => {
  return !sortField
    ? dataset
    : { id: dataset.id, data: sortData(dataset.data, sortField), fields: sortedFields(dataset.fields, sortField) };
};

const sortData = (data, sortField) => {
  return [ ...data ].sort(comparator(sortField));
};

const comparator = field => {
  return (o1, o2) => {
    const a = deriveComparable(o1, field);
    const b = deriveComparable(o2, field);
    let result = field.isStringData ? sortAsNonNumbers(a, b) : sortAsNumbers(a, b);
    return order(result, determineOrder(field));
  };
};

const MIN_STRING_VALUE = 'AAA';
const MAX_STRING_VALUE = 'zzz';
const deriveComparable = (o, field) => {
  if (field.type === 'derived') {
    return field.getValue(o);
  } else if (hasProperty(o, field.name)) {
    return getProperty(o, field.name);
  } else if (field.isStringData) {
    return field.orderedBy === ASC ? MIN_STRING_VALUE : MAX_STRING_VALUE;
  } else {
    return field.orderedBy === DESC ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
  }
};

const sortableNumber = x => {
  return x !== '' && !isNaN(x);
};

const sortAsNumbers = (a, b) => {
  if (sortableNumber(a) && sortableNumber(b)) {
    return a - b;
  } else if (sortableNumber(a)) {
    return -1;
  } else if (sortableNumber(b)) {
    return 1;
  } else {
    return 0;
  }
};

const sortAsNonNumbers = (a, b) => {
  if (a && b) {
    return (a > b) - (a < b);
  } else if (a) {
    return -1;
  } else {
    return 1;
  }
};

const order = (result, order) => {
  return result * order;
};

const sortedFields = (fields, sortField) => {
  return fields.map(field => newField(field, sortField));
};

const newField = (field, sortField = {}) => {
  let orderedBy = field.name === sortField.name ? determineOrder(sortField) : NONE;
  return { ...field, orderedBy };
};

const determineOrder = field => {
  return field.orderedBy
    ? reverse(field.orderedBy)
    : field.isStringData ? ASC : DESC;
};

const reverse = order => {
  return order * -1;
};
