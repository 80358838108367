import { createContext, useContext, useMemo, useState } from 'react';
import { defaultFieldGroup } from './fields';

const MatchupsFieldGroupContext = createContext(null);

export const useFieldGroupContext = () => useContext(MatchupsFieldGroupContext);

export const MatchupsFieldGroupProvider = ({ children }) => {
  const [ fieldGroup, setFieldGroup ] = useState(defaultFieldGroup());
  const initial = useMemo(() => ({
    fieldGroup,
    setFieldGroup
  }), [ fieldGroup, setFieldGroup ]);
  return (
    <MatchupsFieldGroupContext.Provider value={initial} >
      { children }
    </MatchupsFieldGroupContext.Provider>
  );
};
