import { getFields } from 'app/players';

export const analysisFields = () => [
  {
    name: 'slatePosition',
    header: 'pos',
    isStringData: true
  },
  ...getFields([
    'name',
    'team',
    'opponent',
    'salary',
    'target'
  ]),
  {
    name: 'count',
    header: 'count'
  },
  {
    name: 'diff',
    header: 'diff'
  },
  {
    name: 'percentage',
    type: 'formatted',
    header: '%',
    getFormatted: player => `${player.percentage}%`
  }
];

export const lineupFields = () => [
  {
    name: 'slatePosition',
    header: 'pos',
    tdClassname: 'text-start',
    isStringData: true
  },
  ...getFields([
    'name',
    'team',
    'opponent',
    'salary'
  ])
];
