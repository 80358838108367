import { useInjuryReportFilters } from './redux';
import { useInjuries } from 'app/api';
import { DataTable } from 'app/components';
import { useInjuryReportFields } from 'app/injuries';
import { useSlate } from 'app/slates';
import MinGamesPlayedFilter from './MinGamesPlayedFilter';
import MinSnapPercentageFilter from './MinSnapPercentageFilter';
import StatusFilter from './StatusFilter';

const InjuryReport = ({ additionalFilters, hideTeam = false }) => {

  const slate = useSlate();
  const filters = [ ...useInjuryReportFilters(slate), ...additionalFilters ];
  const data = useInjuries(slate.week).filter(player => filters.every(filter => filter.evaluate(player)));
  const fields = useInjuryReportFields(hideTeam);

  const dataset = { id: 'injuries', data, fields };

  const tableConfig = {
    classNames: 'border'
  };

  return (
    <>
      <div className="ms-2 mb-3">
        <StatusFilter slate={slate}/>
        <MinGamesPlayedFilter slate={slate}/>
        <MinSnapPercentageFilter slate={slate}/>
      </div>
      <div>
        <DataTable config={tableConfig} dataset={dataset}/>
      </div>
    </>
  );
};

export default InjuryReport;
