import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLineupEditorContext } from './LineupEditorContext';
import { useSlate } from 'app/slates';
import useLineupFactory from '../LineupFactory';

const RemovePlayer = ({ record }) => {

  const slate = useSlate();
  const { lineup, setLineup } = useLineupEditorContext();
  const lineupFactory = useLineupFactory(slate);

  const remove = useCallback(player => () => {
    lineup.remove(player);
    setLineup(lineupFactory.make(lineup));
  }, [ lineup, lineupFactory, setLineup ]);

  return (
    <span>
      {
        record.salary
          ? <FontAwesomeIcon icon="fa-solid fa-circle-minus" color="#f00" size="xl" className="pointer" onClick={remove(record)} />
          : <span />
      }
    </span>
  );
};

export default RemovePlayer;
