import { getFields } from './fields';
import { useFieldGroupContext } from './MatchupsFieldGroupContext';
import { useMatchups } from 'app/api';
import { DataTable } from 'app/components';
import { useSlate, useSlateTeamsFilter } from 'app/slates';
import MatchupsFieldGroupSelector from './MatchupsFieldGroupSelector';

const MatchupsView = () => {

  const slate = useSlate();
  const filters = useSlateTeamsFilter(slate);
  const data = useMatchups(slate.week).filter(matchup => filters.every(filter => filter.evaluate(matchup)));
  const { fieldGroup } = useFieldGroupContext();
  const fields = getFields(fieldGroup);

  const dataset = { id: 'matchups', data, fields };

  const tableConfig = { classNames: 'border' };

  return (
    <div className="my-4">
      <div className="mb-4 text-end">
        <MatchupsFieldGroupSelector />
      </div>
      <DataTable config={tableConfig} dataset={dataset} />
    </div>
  );
};

export default MatchupsView;
