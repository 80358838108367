import { DB, DL, LB, OL, QB, RB, TE, WR } from 'app/constants';
import { makeTeamModalLink } from 'app/teams';
import { upToTwoDecimals } from 'app/util';

export const fieldGroups = () => [
  {
    id: 'summary',
    text: 'Summary'
  },
  {
    id: 'passing',
    text: 'Passing'
  },
  {
    id: 'rushing',
    text: 'Rushing'
  },
  {
    id: 'defense',
    text: 'Defense'
  },
  {
    id: 'injuries',
    text: 'Injuries'
  }
];

export const defaultFieldGroup = () => fieldGroups()[0];

const fieldDictionary = () => ({
  off: {
    name: 'offense.name',
    type: 'component',
    header: 'off',
    isStringData: true,
    Component: makeTeamModalLink('Offense', 'offense.name')
  },
  def: {
    name: 'defense.name',
    type: 'component',
    header: 'def',
    isStringData: true,
    Component: makeTeamModalLink('Defense', 'defense.name')
  },
  opyg: {
    name: 'offense.passYdsPerGame',
    header: 'offYds/Gm'
  },
  dpyg: {
    name: 'defense.passYdsPerGame',
    header: 'defYds/Gm'
  },
  apyg: {
    name: 'apyg',
    type: 'derived',
    header: 'avgYds/Gm',
    getValue: matchup => upToTwoDecimals((matchup.offense.passYdsPerGame + matchup.defense.passYdsPerGame) / 2)
  },
  optd: {
    name: 'offense.passTds',
    header: 'offTds'
  },
  dptd: {
    name: 'defense.passTds',
    header: 'defTds'
  },
  aptd: {
    name: 'aptd',
    type: 'derived',
    header: 'avgPassTds',
    getValue: matchup => upToTwoDecimals((matchup.offense.passTds + matchup.defense.passTds) / 2)
  },
  oint: {
    name: 'offense.interceptions',
    header: 'offInts'
  },
  dint: {
    name: 'defense.interceptions',
    header: 'defInts'
  },
  aint: {
    name: 'aint',
    type: 'derived',
    header: 'avgInts',
    getValue: matchup => upToTwoDecimals((matchup.offense.interceptions + matchup.defense.interceptions) / 2)
  },
  osg: {
    name: 'offense.sacksAllowed',
    header: 'offSks'
  },
  dsg: {
    name: 'defense.sacks',
    header: 'defSks'
  },
  asg: {
    name: 'asg',
    type: 'derived',
    header: 'avgSacks',
    getValue: matchup => upToTwoDecimals((matchup.offense.sacksAllowed + matchup.defense.sacks) / 2)
  },
  org: {
    name: 'offense.rushesPerGame',
    header: 'offRsh/Gm'
  },
  oayr: {
    name: 'offense.ydsPerRush',
    header: 'offYds/Rsh'
  },
  dayr: {
    name: 'defense.avgRushYds',
    header: 'defYds/Rsh'
  },
  aayr: {
    name: 'aayr',
    type: 'derived',
    header: 'avgYds/Rsh',
    getValue: matchup => upToTwoDecimals((matchup.offense.ydsPerRush + matchup.defense.avgRushYds) / 2)
  },
  oryg: {
    name: 'offense.rushYdsPerGame',
    header: 'offYds/Gm'
  },
  dryg: {
    name: 'defense.rushYdsPerGame',
    header: 'defYds/Gm'
  },
  aryg: {
    name: 'aryg',
    type: 'derived',
    header: 'avgYds/Gm',
    getValue: matchup => upToTwoDecimals((matchup.offense.rushYdsPerGame + matchup.defense.rushYdsPerGame) / 2)
  },
  ortd: {
    name: 'offense.rushTds',
    header: 'offTds'
  },
  drtd: {
    name: 'defense.rushTds',
    header: 'defTds'
  },
  artd: {
    name: 'artd',
    type: 'derived',
    header: 'avgRshTds',
    getValue: matchup => upToTwoDecimals((matchup.offense.rushTds + matchup.defense.rushTds) / 2)
  },
  opg: {
    name: 'offense.pointsPerGame',
    header: 'offPts/Gm'
  },
  dpg: {
    name: 'defense.pointsPerGame',
    header: 'defPts/Gm'
  },
  apg: {
    name: 'apg',
    type: 'derived',
    header: 'avgPts/Gm',
    getValue: matchup => upToTwoDecimals((matchup.offense.pointsPerGame + matchup.defense.pointsPerGame) / 2)
  },
  otg: {
    name: 'offense.turnovers',
    header: 'offTnvrs'
  },
  dtg: {
    name: 'defense.turnovers',
    header: 'defTnvrs'
  },
  atg: {
    name: 'atg',
    type: 'derived',
    header: 'avgTurnovers',
    getValue: matchup => upToTwoDecimals((matchup.offense.turnovers + matchup.defense.turnovers) / 2)
  },
  oimp: {
    name: 'offense.implied',
    header: 'offImplied'
  },
  total: {
    name: 'total',
    header: 'gmTotal'
  },
  odslg: {
    name: 'offense.lastGame',
    header: 'offDaysLstGm'
  },
  ddslg: {
    name: 'defense.lastGame',
    header: 'defDaysLstGm'
  },
  diffslg: {
    name: 'diffslg',
    type: 'derived',
    header: 'diffDaysLstGm',
    getValue: matchup => matchup.offense.lastGame - matchup.defense.lastGame
  },
  qbfte: {
    name: 'offense.injuriesSummary.QB.fte',
    header: 'qbFte'
  },
  rbfte: {
    name: 'offense.injuriesSummary.RB.fte',
    header: 'rbFte'
  },
  wrfte: {
    name: 'offense.injuriesSummary.WR.fte',
    header: 'wrFte'
  },
  tefte: {
    name: 'offense.injuriesSummary.TE.fte',
    header: 'teFte'
  },
  olfte: {
    name: 'offense.injuriesSummary.OL.fte',
    header: 'olFte'
  },
  dlfte: {
    name: 'defense.injuriesSummary.DL.fte',
    header: 'dlFte'
  },
  lbfte: {
    name: 'defense.injuriesSummary.LB.fte',
    header: 'lbFte'
  },
  dbfte: {
    name: 'defense.injuriesSummary.DB.fte',
    header: 'dbFte'
  },
  olvsdl: {
    name: 'olvsdl',
    type: 'derived',
    header: 'olVsDl',
    getValue: matchup => upToTwoDecimals(matchup.defense.injuriesSummary[DL].fte - matchup.offense.injuriesSummary[OL].fte)
  },
  ovsd: {
    name: 'ovsd',
    type: 'derived',
    header: 'oVsD',
    getValue: matchup => {
      const dFte = [ DL, LB, DB ].reduce((sum, pg) => sum + matchup.defense.injuriesSummary[pg].fte, 0);
      const oFte = [ QB, RB, WR, TE, OL ].reduce((sum, pg) => sum + matchup.offense.injuriesSummary[pg].fte, 0);
      return upToTwoDecimals(dFte - oFte);
    }
  }
});

const fieldIdsFor = fieldGroupId => {
  return {
    summary: [
      'off',
      'def',
      'opg',
      'dpg',
      'apg',
      'oimp',
      'total',
      'odslg',
      'ddslg',
      'diffslg'
    ],
    passing: [
      'off',
      'def',
      'opyg',
      'dpyg',
      'apyg',
      'optd',
      'dptd',
      'aptd',
      'oint',
      'dint',
      'aint',
      'osg',
      'dsg',
      'asg'
    ],
    rushing: [
      'off',
      'def',
      'org',
      'oayr',
      'dayr',
      'aayr',
      'oryg',
      'dryg',
      'aryg',
      'ortd',
      'drtd',
      'artd'
    ],
    defense: [
      'off',
      'def',
      'opg',
      'dpg',
      'apg',
      'osg',
      'dsg',
      'asg',
      'otg',
      'dtg',
      'atg'
    ],
    injuries: [
      'off',
      'def',
      'qbfte',
      'rbfte',
      'wrfte',
      'tefte',
      'olfte',
      'dlfte',
      'lbfte',
      'dbfte',
      'olvsdl',
      'ovsd'
    ]
  }[fieldGroupId];
};

export const getFields = fieldGroup => {
  const dict = fieldDictionary();
  return fieldIdsFor(fieldGroup.id).map(id => dict[id]);
};
