import { useSlate, useSlateTeamsFilter } from 'app/slates';
import PlayerStats from './PlayerStats';

const PlayerStatsView = () => {

  const slate = useSlate();

  return <PlayerStats additionalFilters={useSlateTeamsFilter(slate)} />;
};

export default PlayerStatsView;
