import { useTeam } from 'app/api';
import { DB, DL, LB, OL, QB, RB, TE, WR } from 'app/constants';

const InjuriesSummaryTab = ({ week, game }) => {

  const away = useTeam(game.away, week);
  const home = useTeam(game.home, week);

  return (
    <div className="row my-4">
      <Summary team={away} />
      <Summary team={home} />
    </div>
  );
};

const Summary = ({ team }) => {
  return (
    <>
      <div className="col-1">&nbsp;</div>
      <div className="col-4">
        <table className="table table-sm centered-columns">
          <thead>
            <tr>
              <th colSpan={4}>{team.name}</th>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <th>players</th>
              <th>fte</th>
            </tr>
          </thead>
          <tbody>
            {
              [QB, RB, WR, TE, OL, DL, LB, DB ].map(pg => {
                return (
                  <tr key={pg}>
                    <th>{pg}</th>
                    <td>{team.injuriesSummary[pg].injuredPlayers}</td>
                    <td>{team.injuriesSummary[pg].fte}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InjuriesSummaryTab;
