import { useSelector } from 'react-redux';
import { useTeams } from 'app/api';


/*
 * hooks
 */
export const useGames = (team, week) => {
  const games = useSelector(({ teams }) => teams.games);
  return games[team].filter(datum => datum.week < week);
};

const useUnit = (team, unit, week) => {
  const teams = useTeams(week);
  return { ...teams[team], ...teams[team][unit] };
};

export const useOffense = (team, week) => useUnit(team, 'offense', week);

export const useDefense = (team, week) => useUnit(team, 'defense', week);
