import { DepthChart } from 'app/components';

const DepthChartsTab = ({ away, home, week }) => {
  return (
    <>
      <div className="my-4">
        <h5 className="mx-3">{away}</h5>
        <DepthChart team={away} week={week}/>
      </div>
      <div className="my-4">
        <h5 className="mx-3">{home}</h5>
        <DepthChart team={home} week={week}/>
      </div>
    </>
  );
};

export default DepthChartsTab;
