import { defenseStatsFields } from './fields';
import { useDefenseStats } from 'app/api';
import { DataTable } from 'app/components';
import { useSlate, useSlateTeamsFilter } from 'app/slates';

const DefensesView = () => {

  const slate = useSlate();
  const filters = useSlateTeamsFilter(slate);
  const data = useDefenseStats(slate.week).filter(defense => filters.every(filter => filter.evaluate(defense)));
  const fields = defenseStatsFields()

  const dataset = { id: 'defenses', data, fields };

  const tableConfig = { classNames: 'border' };

  return (
    <div className="my-4">
      <DataTable config={tableConfig} dataset={dataset} />
    </div>
  );
};

export default DefensesView;
