import { useHomeAwayFilter } from './HomeAwayFilter';
import { SlatePlayersDataTable, slatePlayersFields } from 'app/players';
import { useSlate } from 'app/slates';

const PlayersTab = () => {

  const slate = useSlate();

  return (
    <div className="my-4">
      <SlatePlayersDataTable additionalFilters={[ useHomeAwayFilter(slate) ]} fieldsProvider={slatePlayersFields} />
    </div>
  );
};

export default PlayersTab;
