import { Dropdown, DropdownButton } from 'react-bootstrap';
import { fieldGroups } from './fields';
import { useFieldGroupContext } from './SlatePlayersFieldGroupContext';

const SlatePlayersFieldGroupSelector = () => {

  const { fieldGroup, setFieldGroup } = useFieldGroupContext();

  const clickHandler = fg => () => setFieldGroup(fg);

  return (
    <DropdownButton title={fieldGroup.text}>
      { fieldGroups().map(fg => <Dropdown.Item key={fg.id} onClick={clickHandler(fg)}>{fg.text}</Dropdown.Item>) }
    </DropdownButton>
  );
};

export default SlatePlayersFieldGroupSelector;
