import { useCallback } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useSelectedTeam, useSelectedTeamDispatch } from './TeamModalContext';
import { defenseGameFields, offenseGameFields } from '../fields';
import { useGames } from '../redux';
import { useGame, useTeam } from 'app/api';
import { DataTable, DepthChart } from 'app/components';
import { InjuryReport } from 'app/injuries';
import { PlayerStats } from 'app/players';
import { useWeek } from 'app/slates/SlateViewContext';

const playerFilter = team => ({ evaluate: player => player.team === team.name });

const Header = ({ team }) => {
  return (
    <div className="container">
      <div className="row">
        <Identification team={team} />
        <Miscellany />
      </div>
    </div>
  );
};

const Identification = ({ team }) => {
  return (
    <div className="col-5">
      <div className="row">
        <div className="col">
          <h2>{team.name}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h5>{team.record}</h5>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h5>{team.versus ? `${team.versus} | ${team.game.day} ${team.game.time}` : ''}</h5>
        </div>
      </div>
    </div>
  );
};

const Miscellany = () => {
  return (
    <div className="col-3">
      <div className="row">
        <table>
          <tbody>
            <tr />
          </tbody>
        </table>
      </div>
    </div>
  );
};

const OffenseGameLogs = ({ team }) => {

  const week = useWeek();
  const games = useGames(team.name, week);
  const dataset = {
    data: games.map(g => g['offense']),
    fields: offenseGameFields()
  };

  return <GameLogs dataset={dataset} />;
};

const DefenseGameLogs = ({ team }) => {

  const week = useWeek();
  const games = useGames(team.name, week);
  const dataset = {
    data: games.map(g => g['defense']),
    fields: defenseGameFields()
  };

  return <GameLogs dataset={dataset} />;
};

const GameLogs = ({ dataset }) => {

  const tableConfig = {
    sorting: false,
    classNames: 'table-sm'
  };

  return <DataTable config={tableConfig} dataset={dataset} />;
};

const Injuries = ({ team }) => {
  return (
    <div className="my-4">
      <InjuryReport additionalFilters={[ playerFilter(team) ]} hideTeam={true} />
    </div>
  );
};

const PlayersStatsTab = ({ team }) => {
  return (
    <div className="my-4">
      <PlayerStats additionalFilters={[ playerFilter(team) ]} />
    </div>
  );
};

const TeamModal = () => {

  const week = useWeek();
  const selected = useSelectedTeam();
  const show = !!selected;
  const game = useGame(selected.name, week) || {};
  const team = { ...selected, ...useTeam(selected.name, week), game };

  const dispatchSelectedTeam = useSelectedTeamDispatch();
  const onHide = useCallback(() => dispatchSelectedTeam(), [ dispatchSelectedTeam ]);

  return (
    <Modal
      centered
      show={show}
      animation={false}
      onHide={onHide}
      dialogClassName="data-modal"
      aria-labelledby="data-modal-title"
    >
      <Modal.Header closeButton>
        <Header team={team} />
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey={team.unit.toLowerCase()}>
          <Tab key="offense" eventKey="offense" title="Offense">
            <OffenseGameLogs team={team} />
          </Tab>
          <Tab key="defense" eventKey="defense" title="Defense">
            <DefenseGameLogs team={team} />
          </Tab>
          <Tab key="depthchart" eventKey="depthchart" title="Depth Chart">
            <DepthChart team={team.name} week={week} />
          </Tab>
          <Tab key="injuries" eventKey="injuries" title="Injuries">
            <Injuries team={team} />
          </Tab>
          <Tab key="playerStats" eventKey="playerStats" title="Player Stats">
            <PlayersStatsTab team={team} />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

const TeamModalWrapper = () => {
  return !!useSelectedTeam() && <TeamModal />;
};

export default TeamModalWrapper;
