import { Modal, Tab, Tabs } from 'react-bootstrap';
import { playerGamesFields, playerSnapsFields, positionVsTeamFields } from './fields';
import { usePlayerGames } from '../redux';
import { usePositionVsTeamData } from 'app/api';
import { DataTable, NewsSearchLink, PracticeReport } from 'app/components';
import { DST, QB, RB, TE, WR } from 'app/constants';
import { useSlate } from 'app/slates';
import { fourTimesValue } from 'app/util/calculations';
import ModalShowdownPositionFilter from './ModalShowdownPositionFilter';
import InterestIndicator from '../InterestIndicator';

const PlayerModalView = ({ player }) => {

  const slate = useSlate();
  const games = usePlayerGames(player, slate.week);
  const posVsTeamsDataset = {
    data: usePositionVsTeamData(player?.position, player?.opponent, slate.week),
    fields: positionVsTeamFields(player?.position)
  };

  const tabs = makeTabs(player, games, posVsTeamsDataset);
  const defaultTab = [ QB, RB, WR, TE, DST ].includes(player.position) ? 'playerGames' : 'snapsData';

  return (
    <>
      <Modal.Header closeButton>
        <Header player={player} slate={slate} />
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey={defaultTab}>
          { tabs }
        </Tabs>
      </Modal.Body>
    </>
  );
};

const makeTabs = (player, games, posVsTeamsDataset) => {
  const tabs = [];
  if ([ QB, RB, WR, TE, DST ].includes(player.position)) {
    tabs.push(
      <Tab key="playerGames" eventKey="playerGames" title="Games">
        <PlayerGames player={player} games={games} />
      </Tab>
    );
  }
  if (player.position !== DST) {
    tabs.push(
      <Tab key="snapsData" eventKey="snapsData" title="Snap %s">
        <SnapData games={games} />
      </Tab>
    );
  }
  if ([ QB, RB, WR, TE, DST ].includes(player.position) && player.opponent) {
    tabs.push(
      <Tab key="opponent" eventKey="opponent" title={`${player.position}s vs ${player.opponent}`}>
        <PositionVsTeam dataset={posVsTeamsDataset} />
      </Tab>
    );
  }
  if (player.position !== DST && player.opponent) {
    tabs.push(
      <Tab key="practiceReport" eventKey="practiceReport" title="Practice">
        <PracticeReport report={player} />
      </Tab>
    );
  }
  return tabs;
};

const Header = ({ player, slate }) => {
  return (
    <div className="container">
      <div className="row">
        <Identification player={player} />
        <Stats player={player} />
        <Miscellany player={player} slate={slate} />
      </div>
    </div>
  );
};

const Identification = ({ player }) => {
  const vs = (player => {
    if (!player.gameId) {
      return '';
    }
    const teamIndex = player.gameId.indexOf(player.team);
    const oppIndex = player.gameId.indexOf(player.opponent);
    return teamIndex < oppIndex ? 'vs.' : '@';
  })(player);
  const salary = (player => {
    if (!player.salary) {
      return '';
    }
    return `| $${player.salary.toLocaleString()}`;
  })(player);
  return (
    <div className="col-6">
      <div className="row">
        <div className="col">
          <h2>{player.name}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h5>{player.team} | {player.position}</h5>
          <h5>{vs} {player.opponent} {salary}</h5>
        </div>
      </div>
      <div className="row">
        { !!player.status && <div className="col">{player.status} | {player.nature}</div> }
      </div>
    </div>
  );
};

const Stats = ({ player }) => {
  const avgFps = player.avgFps || player.avgFps === 0
    ? (
      <>
        <td className="pr-4 font-weight-bold">Avg Points:</td>
        <td>{player.avgFps}</td>
      </>
    )
    : <td />;
  const maxFps = player.maxFps || player.maxFps === 0
    ? (
      <>
        <td className="pr-4 font-weight-bold">Max Points:</td>
        <td>{player.maxFps}</td>
      </>
    )
    : <td />;
  const vsOpponent = player.opponent
    ? (
      <>
        <td className="pr-4 font-weight-bold">{player.position} v. {player.opponent} Points:</td>
        <td>{player.oppPtsAgst}</td>
      </>
    )
    : <td />;
  const x4Points = player.salary
    ? (
      <>
        <td className="pr-4 font-weight-bold">x4 Points:</td>
        <td>{ !!player.salary && fourTimesValue(player.salary) }</td>
      </>
    )
    : <td />;
  return (
    <div className="col-3">
      <div className="row">
        <table>
          <tbody>
            <tr>{avgFps}</tr>
            <tr>{maxFps}</tr>
            <tr>{vsOpponent}</tr>
            <tr>{x4Points}</tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Miscellany = ({ player, slate }) => {
  return (
    <div className="col-3">
      <div className="row">
        <table>
          <tbody>
            { !!player.vendorId && <ModalShowdownPositionFilter slate={slate} /> }
            {
              player.salary
                ? (
                  <tr>
                    <td className="font-weight-bold">
                      Like/Lock:
                    </td>
                    <td>
                      <InterestIndicator record={player} />
                    </td>
                  </tr>
                )
                : <tr />
            }
            <tr>
              <td className="font-weight-bold">
                News:
              </td>
              <td>
                <NewsSearchLink subject={player.name} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PlayerGames = ({ player, games }) => {

  const dataset = {
    data: games,
    fields: playerGamesFields(player.position)
  };

  const tableConfig = {
    sorting: false,
    classNames: 'table-sm border-sides'
  };

  return games ? <DataTable config={tableConfig} dataset={dataset} /> : <div />;
};

const PositionVsTeam = ({ dataset }) => {

  const tableConfig = {
    sorting: false,
    classNames: 'table-sm border-sides',
    rowClassNameGenerator: (() => {
      let previous = '';
      let rowClass = 'table-active';
      return row => {
        if (row.versus !== previous) {
          previous = row.versus;
          rowClass = rowClass === '' ? 'table-active' : '';
        }
        return rowClass;
      };
    })()
  };

  return dataset.data ? <DataTable config={tableConfig} dataset={dataset} /> : <div />;
};

const SnapData = ({ games }) => {

  const dataset = {
    data: games,
    fields: playerSnapsFields()
  };

  const tableConfig = {
    sorting: false,
    classNames: 'table-sm border-sides'
  };

  return games ? <DataTable config={tableConfig} dataset={dataset} /> : <div />;
};

export default PlayerModalView;
