const DomeIcon = ({ tooltip, fillColor }) => {

  const fill = fillColor || 'currentColor';

  return (
    <svg viewBox="0 0 256 256" fill={fill} height="45px" width="45px">
      <title>{tooltip}</title>
      <path d="M27.0777588,176.00003 C27.0260796,174.673098 27,173.339595 27,172 C27,116.21924 72.2192403,71 128,71 C183.78076,71 229,116.21924 229,172 C229,173.339595 228.97392,174.673098 228.922241,176.00003 L27.0777576,176 Z" />
      <polygon points="27 194 229 194 232 242 24 242" />
      <path d="M128,13 C146.286055,18.3333333 164.619388,19.3333333 183,16 C195.656732,13.7046952 194,67 183,57 C174.979025,49.7082043 156.645691,48.374871 128,53 L128,13 Z" />
      <rect x="124" y="13" width="8" height="68" rx="4" />
    </svg>
  );
};

export default DomeIcon;
