import { useCallback } from 'react';
import { useSelectedPlayerIdDispatch, useShowPlayerModalDispatch } from 'app/players';

const PlayerName = ({ record }) => {

  const select = useSelectedPlayerIdDispatch();
  const showModal = useShowPlayerModalDispatch();

  const onClick = useCallback(() => {
    select(record.id);
    showModal(true);
  }, [ record, select, showModal ]);

  return <span className="link" onClick={onClick}>{record.name}</span>;
};

export default PlayerName;
