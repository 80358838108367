import { useCallback } from 'react';
import { useDepthChart, usePlayer } from 'app/api';
import { QB, RB, TE, WR } from 'app/constants';
import { indication } from 'app/injuries';
import { useSelectedPlayerIdDispatch, useShowPlayerModalDispatch } from 'app/players';

const PlayerDepth = ({ pd, week }) => {

  const player = usePlayer(week, pd.id);
  const statusIndication = indication(player.status);

  const dispatchSelectedPlayerId = useSelectedPlayerIdDispatch();
  const dispatchShowPlayerModal = useShowPlayerModalDispatch();

  const onClick = useCallback(() => {
    dispatchSelectedPlayerId(player.id);
    dispatchShowPlayerModal(true);
  }, [ dispatchSelectedPlayerId, dispatchShowPlayerModal, player ]);

  return (
    <tr key={pd.id}>
      <td>{pd.depth}.</td>
      <td>
        <span className="link" onClick={onClick}>{player.name}</span>
        <span className="status-indication">{statusIndication}</span>
      </td>
    </tr>
  );
};

const PositionDepth = ({ depthChart, position, week }) => {
  return (
    <div className="col-3">
      <table className="table table-sm table-borderless d-inline m-3">
        <thead>
          <tr>
            <th>{position}</th>
          </tr>
        </thead>
        <tbody>
          { depthChart[position].map(pd => <PlayerDepth key={pd.id} pd={pd} week={week} />) }
        </tbody>
      </table>
    </div>
  );
};

const DepthChart = ({ team, week }) => {
  const depthChart = useDepthChart(team, week);
  return (
    <div className="row">
      { [ QB, RB, WR, TE ].map(position => (
        <PositionDepth
          key={`${team}-${position}-${week}`}
          depthChart={depthChart}
          position={position}
          week={week}
        />
      )) }
    </div>
  );
};

export default DepthChart;
