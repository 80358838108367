export const ALL = 'ALL';

export const CLASSIC = 'CLASSIC';
export const SHOWDOWN = 'SHOWDOWN';

export const QB = 'QB';
export const RB = 'RB';
export const WR = 'WR';
export const TE = 'TE';
export const OL = 'OL';
export const DL = 'DL';
export const LB = 'LB';
export const DB = 'DB';
export const K = 'K';
export const DST = 'DST';
export const CPT = 'CPT';
export const FLEX = 'FLEX';
