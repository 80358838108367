import { Tab, Tabs } from 'react-bootstrap';
import { fieldGroups } from './fields';
import { useFieldGroupContext } from './PlayerStatsFieldGroupContext';

const PlayerStatsFieldGroupSelector = () => {

  const { fieldGroup, setFieldGroup } = useFieldGroupContext();

  return (
    <Tabs activeKey={fieldGroup} onSelect={setFieldGroup}>
      { fieldGroups().map(fg => <Tab key={fg.id} eventKey={fg.id} title={fg.text} />) }
    </Tabs>
  );
};

export default PlayerStatsFieldGroupSelector;
