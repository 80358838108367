import PlayerName from './PlayerName';

export const useInjuryReportFields = hideTeam => {
  return [
    {
      name: 'name',
      type: 'component',
      header: 'name',
      tdClassname: 'text-start text-nowrap',
      isStringData: true,
      Component: PlayerName
    },
    {
      name: 'position',
      header: 'pos',
      isStringData: true
    },
    {
      name: 'positionGroup',
      header: 'grp',
      isStringData: true
    },
    {
      name: 'team',
      header: 'tm',
      isStringData: true
    },
    {
      name: 'gamesPlayed',
      header: 'gms'
    },
    {
      name: 'avgPercentSnaps',
      header: 'avgSnap%'
    },
    {
      name: 'status',
      header: 'status',
      isStringData: true
    },
    {
      name: 'nature',
      header: 'nature',
      isStringData: true
    },
    {
      name: 'monday',
      header: 'mon',
      isStringData: true
    },
    {
      name: 'tuesday',
      header: 'tue',
      isStringData: true
    },
    {
      name: 'wednesday',
      header: 'wed',
      isStringData: true
    },
    {
      name: 'thursday',
      header: 'thu',
      isStringData: true
    },
    {
      name: 'friday',
      header: 'fri',
      isStringData: true
    },
    {
      name: 'saturday',
      header: 'sat',
      isStringData: true
    }
  ].filter(field => !hideTeam || field.name !== 'team');
};
